import Parse from "parse";

export async function fetchRelectureData(
  fileDoneId,
  id,
  typeExercice
) {
  try {
    // Préparation des données pour l'appel Cloud
    // On envoie uniquement les identifiants
    const params = {
      fileDoneId,
      selectedUserId: id,
      typeExercice
    };
    console.log("fetchRelectureData params", params);
    // Appel de la fonction Cloud Parse
    const result = await Parse.Cloud.run("fetchRelectureData", params);
    
    return result;
  } catch (error) {
    console.error("Erreur lors de l'appel à fetchRelectureData:", error);
    throw error;
  }
}