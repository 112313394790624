<template>
  <div class="flex-container">
    <div class="main marginMain">
      <Header
        :key="keyHeader"
        class="IndexNavbar"
        @blocName="blocName"
        :UserSelectedByTherapeute="user"
        @backToActivitySpacePro="handleBackToActivitySpacePro"
        @goToProfile="handleGoToProfile"
        @isLoadingActivitySpace="handleIsLoadingActivitySpace"
        :idPatientTherapeute="objectIdPatient"
      ></Header>
      <div class="loader-container" v-if="isLoadingActivitySpace">
        <div class="loader-wrapper">
          <loaderComponent />
          <p class="loading-text">Chargement en cours..</p>
        </div>
      </div>
      <div class="main-container">
        <div
          v-if="$Therapeute && $versionState.NewVersionEnabled && !$AccountLimited"
          class="vertical-menu-container"
          :class="{ 'menu-closed': isMenuClosed }"
        >
        <div v-if="$IsTherapeuteWithoutIdPatient"
     class="menu-toggle"
     @click="toggleMenu"
     :class="{ closed: isMenuClosed }"
>
  <i v-if="isMenuClosed" class="fas fa-users"></i>
  <i v-else class="fas fa-times"></i>
</div>
<verticalMenu
  v-if="!isMenuClosed"
  @linkUser="handleIdlinkUser"
  @isLoadingActivitySpace="handleIsLoadingActivitySpace"
  @IdUser="handleIdUser"
  @ReloadHeader="handleReloadHeader"
  @UndisplayHeader="handleUnDisplayHeader"
  @showToastSuccess="notifySuccess"
  @showToastError="notifyError"
  @suivi="handleSuiviData"
  @edit="handleUpdateUser"
  @createUser="handleEmitCreateUser"
  @ResetMenu="ResetMenu"
  @ResetHome="ResetHome"
  :tabAdd="tabAdd"
  :tabRemove="tabRemove"
  :editUser="editUser"
  :createUserBoolean="createUserBoolean"
  ref="verticalMenu"
  :key="keyMenu"
/>
        </div>
        <div class="content-container overflow-y-auto max-h-screen width100">
          <profile
            @BackToActivitySpace="handleBackToActivitySpacePro"
            @success="handleSuccess"
            @error="handleError"
            v-if="blocSelected === 'profile'"
            :userProfileInformations="userProfileInformations"
            ref="newProfile"
            :scrollToAbonnement="scrollToAbonnement"
          ></profile>
          <essaiGratuitBandeau
            v-if="
              $versionState.NewVersionEnabled &&
              !objectIdPatient &&
              $EssaiGratuit &&
              blocSelected === 'programmes'
            "
            @subscribe="handleGoToAbonnement"
          />
          <div v-if="!editUser">
            <!-- <newSocket/> -->
            <SuiviUtilisateur
              v-if="
                blocSelected === 'suiviUtilisateur' &&
                $versionState.NewVersionEnabled &&
                SuiviDataUser
              "
              :SuiviDataUser="SuiviDataUser"
              :IdUser="IdSuiviDataUser"
              @isLoadingActivitySpace="handleIsLoadingActivitySpace"
              @backToActivitySpace="handleBackToActivitySpacePro"
            ></SuiviUtilisateur>
            <listProtocol
              v-on:pop-up-contact="popUpContactEmitted"
              :ParamJeuGuidee="ParamJeuGuidee"
              :SeanceSelected="SeanceSelected"
              class="container-protocol"
              :idPatientTherapeute="objectIdPatient"
              v-on:is-display-programmes="onIsDisplayProgrammes"
              v-if="blocSelected == 'programmes' && isProgrammesDisplayed"
              :LinksOfUser="LinksOfUser"
              @linkUser="showPersonnalisationPro"
              @isLoadingActivitySpace="handleIsLoadingActivitySpace"
              @upgrade-requested="handleGoToAbonnement"

            ></listProtocol>
            <listRessources
              :ParamJeu="ParamJeu"
              class="container-protocol"
              :idPatientTherapeute="objectIdPatient"
              v-if="blocSelected == 'programmes' && isDisplayRessources"
              :LinksOfUser="LinksOfUser"
              @linkUser="showPersonnalisationPro"
              @isLoadingActivitySpace="handleIsLoadingActivitySpace"
              @upgrade-requested="handleGoToAbonnement"

            ></listRessources>
            <listEchelles
              v-on:pop-up-contact="popUpContactEmitted"
              class="container-protocol"
              :idPatientTherapeute="objectIdPatient"
              v-if="blocSelected == 'programmes' && isDisplayEchelles"
              :LinksOfUser="LinksOfUser"
              @linkUser="showPersonnalisationPro"
              @isLoadingActivitySpace="handleIsLoadingActivitySpace"
              @upgrade-requested="handleGoToAbonnement"

            ></listEchelles>
          </div>
          <attributionsBrique
            @tabAddAndRemove="handleTabAddAndRemove"
            v-if="blocSelected === 'createUser' || editUser"
            :LinksOfUser="LinksOfUser"
            :createUserBoolean="createUserBoolean"
            @isLoadingActivitySpace="handleIsLoadingActivitySpace"
            @BackToActivitySpace="handleBackToActivitySpacePro"
            @cancel="ResetHome"
            @saveLink="saveLink"
            :editPro="editPro"
            :keyAttriBrique="keyAttriBrique"
          ></attributionsBrique>
          <jitsiMeetVue v-if="blocSelected === 'visio'"></jitsiMeetVue>
          <profil
            @displayHeader="handleDisplayHeader"
            v-if="blocSelected == 'profil'"
          ></profil>
          <beginPage
            @continue="handleContinue"
            v-if="blocSelected == 'beginPage'"
          ></beginPage>
          <beginPage2
            @continue="handleContinue2"
            v-if="blocSelected == 'beginPage2'"
          ></beginPage2>
          <beginPage3
            :idPatientTherapeute="objectIdPatient"
            @continue="handleContinue3"
            v-if="blocSelected == 'beginPage3'"
          ></beginPage3>
        </div>
        <div
          v-if="isMenuClosed && !LinksOfUser"
          class="floating-button"
          @click="toggleMenu"
        >
          <div class="button-content">
            <i class="fas fa-users"></i>
            <span class="button-text">Vos utilisateurs</span>
          </div>
          <div class="pulse-ring"></div>
        </div>
      </div>
    </div>
    <notif-toast ref="notifToast"></notif-toast>
    <router-view></router-view>
    <ContactIcon :idUserMsg="ParamMsg" ref="childRef"></ContactIcon>
    <MajNotification
      v-if="maj.length > 0 && showNotification"
      :maj="maj"
    ></MajNotification>
    <FooterMailBienEtreVue v-if="showModalBienEtre" :key="'bienetre-' + modalKey"></FooterMailBienEtreVue>
    <FooterMailTechnique v-if="showModalTechnique" :key="'technique-' + modalKey"></FooterMailTechnique>
    <FooterA
    class="sticky-footer"
    @openModal="handleOpenModal"
    @openModalTechnique="handleOpenModalTechnique"
    @closeModal="handleCloseModal"
  ></FooterA>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { inject } from "vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import router from "@/router";
import AuthService from "@/services/utilisateurs/authService.js";
import { getUserById } from "@/services/utilisateurs/getUserById";
import { verificationToken } from "@/services/token/verificationToken.js";
import WebSocketService from "@/services/socket/WebSocketService.js";
import {
  setGlobalVariables,
  resetLinksGlobal,
  resetIdPatientGlobal,checkAccountLimited
} from "@/services/utilisateurs/globalSettingsUser.js";
import { getSuiviForUser } from "@/services/utilisateurs/getSuiviForUser.js";
import newSocket from "@/components/newSocket/newSocket.vue";
// Composants chargés dynamiquement
const Header = defineAsyncComponent(() =>
  import("@/components/accueilV2/Header2.vue")
);
const HeaderForPatient = defineAsyncComponent(() =>
  import("@/components/espaceGestionV2/menu/HeaderForPatient.vue")
);
const loaderComponent = defineAsyncComponent(() =>
  import("@/components/loader/loaderClassic.vue")
);

const attributionsBrique = defineAsyncComponent(() =>
  import("@/components/espaceGestionV2/personnalisation/attributionsBrique.vue")
);
const essaiGratuitBandeau = defineAsyncComponent(() =>
  import("@/components/espaceGestionV2/menu/essaiGratuitBandeau.vue")
);
const SuiviUtilisateur = defineAsyncComponent(() =>
  import("@/components/espaceGestionV2/suivi/SuiviUtilisateur.vue")
);

const FooterA = defineAsyncComponent(() =>
  import("@/components/accueilV2/Footer2.vue")
);
const profil = defineAsyncComponent(() => import("@/components/profil.vue"));
const listProtocol = defineAsyncComponent(() =>
  import("@/components/SymbioCenter_Activites/ListProtocolNew.vue")
);
const listRessources = defineAsyncComponent(() =>
  import("@/components/SymbioCenter_Activites/ListRessourcesNew.vue")
);
const listEchelles = defineAsyncComponent(() =>
  import("@/components/SymbioCenter_Activites/ListEchellesNew.vue")
);

const jitsiMeetVue = defineAsyncComponent(() =>
  import("@/components/visio/meetingComponent.vue")
);
const MajNotification = defineAsyncComponent(() =>
  import("@/components/notification/MajNotification.vue")
);
const FooterMailBienEtreVue = defineAsyncComponent(() =>
  import("@/components/accueilV2/FooterMailBienEtre.vue")
);
const FooterMailTechnique = defineAsyncComponent(() =>
  import("@/components/accueilV2/FooterMailTechnique.vue")
);
const ContactIcon = defineAsyncComponent(() =>
  import("@/components/contact/contactIcon.vue")
);

const verticalMenu = defineAsyncComponent(() =>
  import("@/components/espaceGestionV2/menu/verticalMenu.vue")
);
const popUpEval = defineAsyncComponent(() =>
  import("@/components/espaceGestionV2/popUp/popUpEval")
);
const profile = defineAsyncComponent(() =>
  import("@/components/espaceGestionV2/profil/profile.vue")
);
const beginPage = defineAsyncComponent(() =>
  import("@/components/accueilV2/beginPage.vue")
);
const beginPage2 = defineAsyncComponent(() =>
  import("@/components/accueilV2/beginPage2.vue")
);
const beginPage3 = defineAsyncComponent(() =>
  import("@/components/accueilV2/beginPage3.vue")
);

const NotifToast = defineAsyncComponent(() =>
  import("@/components/espaceGestionV2/popUp/NotifToast.vue")
);
import linkService from "@/services/link/linkService.js";

export default {
  name: "Accueil",
  components: {
    FooterA,
    listEchelles,
    profil,
    listProtocol,
    listRessources,
    jitsiMeetVue,
    // MessagerieBottom,
    Header,
    MajNotification,
    // VisioBottom,
    FooterMailBienEtreVue,
    FooterMailTechnique,
    beginPage,
    beginPage2,
    beginPage3,
    ContactIcon,
    verticalMenu,
    loaderComponent,
    HeaderForPatient,
    SuiviUtilisateur,
    NotifToast,
    attributionsBrique,
    popUpEval,
    profile,
    essaiGratuitBandeau,
    newSocket,
  },
  data() {
    return {
      isMenuClosed: false,
      UserSelectedByTherapeute: "",
      blocSelected: "programmes",
      element_user_local: [],
      objectIdPatient: "",
      isProgrammesDisplayed: true,
      isDisplayRessources: true,
      isDisplayEchelles: true,
      foldersBrique: [],
      user: null,
      bgColor: null,
      bgImage: null,
      typeT: null,
      linkedObjectPatient: null,
      Cgu: null,
      lastExercice: null,
      keyProfile: 0,
      keyHeader: 0,
      maj: [],
      showNotification: true,
      showModalTechnique: false,
      showModalBienEtre: false,
      modalKey: 0,
      ParamJeu: null,
      ParamJeuGuidee: null,
      SeanceSelected: null,
      showPopUpContact: false,
      ParamMsg: null,
      LinksOfUser: [],
      isLoadingActivitySpace: false,
      isMenuClosed: false,
      SuiviDataUser: null,
      editUser: false,
      isShowPopUpEval: false,
      tabAdd: [],
      tabRemove: [],
      createUserBoolean: false,
      keyMenu: 0,
      userProfileInformations: null,
      scrollToAbonnement: false,
      editPro: false,
      keyAttriBrique: 0,
    };
  },
  computed: {
    isTherapeute() {
      return !!Parse.User.current()?.attributes?.therapeute;
    },
    idPatient() {
      return this.$IdPatient;
    },
  },
  async created() {
    // Initialisation des configurations
    setGlobalVariables();
    ParseConfig();

    // Exécution parallèle des tâches indépendantes
    await Promise.allSettled([
      this.initializeParams(),
      this.initializeUser(),
      this.verifBfp(),
      this.getLatestMaj(),
      this.verifLink(),
    ]);
  },

  watch: {
    imageTexture() {
      if (this.imageTexture) {
        this.isCardAjoutTexture = true;
      }
    },
    async verifToken() {
      var token = localStorage.getItem("token");
      var loginTimestamp = parseInt(localStorage.getItem("dateConnexion"), 10);
      var result = await verificationToken(token);
      if (Date.now() - loginTimestamp > 12 * 60 * 60 * 1000) {
        // si le token est trop vieux, on le supprime
        localStorage.removeItem("token");
        localStorage.removeItem("idUserForSave");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
      if (result == false) {
        //si le token est mauvais, on le supprime
        localStorage.removeItem("token");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
    },
    imageBackground() {
      if (this.imageBackground) {
        this.isCardAjoutBackground = true;
      }
    },

    user: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  async mounted() {
    ParseConfig();
    checkAccountLimited();
  },

  methods: {
    toggleMenu() {
      this.isMenuClosed = !this.isMenuClosed;
    },
    async initializeParams() {
      try {
        await Promise.all([
          this.recupParamJeu(),
          this.recupParamJeuGuidee(),
          this.recupParamMsg(),
        ]);
      } catch (error) {
        console.error("Erreur lors de l'initialisation des paramètres:", error);
      }
    },
    async verifLink() {
      console.log("LinksGlobal", this.$LinksGlobal);
      if (this.$LinksGlobal) {
        this.LinksOfUser = this.$LinksGlobal;
        console.log("VerifLink1:", this.LinksOfUser);
      } else if (this.$Therapeute) {
        this.LinksOfUser = await Parse.Cloud.run("getUserLinks", {
          objectId: Parse.User.current().id,
        });
        console.log("VerifLink2:", this.LinksOfUser);
      }
    },
    toggleMenu() {
      this.isMenuClosed = !this.isMenuClosed;
    },

    async saveLink() {
      var id = this.$IdPatient || Parse.User.current().id;
      console.log(
        "id",
        this.$IdPatient,
        "user",
        Parse.User.current().id,
        "id",
        id
      );
      this.handleIsLoadingActivitySpace(true);
      var result = await linkService.updateUserLinkRelation(
        id,
        this.tabAdd,
        this.tabRemove
      );
      if (result) {
        console.log("Successfully updated user");
      } else {
        console.log("Failed to update user");
      }
      this.handleIsLoadingActivitySpace(false);
      this.ResetHome();
    },

    async initializeUser() {
      try {
        // Récupération de l'objectId depuis l'URL
        const urlParams = new URLSearchParams(
          window.location.hash.split("?")[1]
        );
        this.objectIdPatient = urlParams.get("objectId");

        // Récupération depuis le localStorage si disponible
        if (localStorage.getItem("idUserForSave")) {
          this.user = await getUserById(localStorage.getItem("idUserForSave"));
          this.objectIdPatient = localStorage.getItem("idUserForSave");
        }

        // Vérification des CGU
        const currentUser = Parse.User.current();
        console.log('CurrentUser:', currentUser);
        if (currentUser && !currentUser.attributes.cgu) {
          this.blocSelected = "beginPage";
        }
      } catch (error) {
        console.error(
          "Erreur lors de l'initialisation de l'utilisateur:",
          error
        );
      }
    },

    handleTabAddAndRemove(tabAdd, tabRemove) {
      // Ajouter les nouveaux éléments au tableau existant
      if (tabAdd && tabAdd.length) {
        // Filtrer pour éviter les doublons
        const newElements = tabAdd.filter(
          (item) => !this.tabAdd.includes(item)
        );
        this.tabAdd = [...this.tabAdd, ...newElements];
      }

      // Ajouter les éléments à supprimer au tableau existant
      if (tabRemove && tabRemove.length) {
        // Filtrer pour éviter les doublons
        const newRemoveElements = tabRemove.filter(
          (item) => !this.tabRemove.includes(item)
        );
        this.tabRemove = [...this.tabRemove, ...newRemoveElements];
      }
    },
    async ResetHome() {
      console.log("RESET HOME EST APPELE");
      this.editUser = false;
      this.resetIdPatient = true;
      this.createUserBoolean = false;
      this.blocSelected = "programmes";
      this.LinksOfUser = [];
      await resetLinksGlobal();
      await resetIdPatientGlobal();
      this.editPro = false;
    },
    async showPersonnalisationPro(link) {
      await this.handleIdlinkUser(link);
      this.blocSelected = "createUser";
      this.editPro = true;
    },
    handleGoToAbonnement() {
      console.log("handleGoToAbonnement");
      this.scrollToAbonnement = true;
      this.handleGoToProfile();
    },
    handlePopUpEval() {
      this.isShowPopUpEval = false;
    },
    handleSuccess() {
      this.notifySuccess("Profil mis à jour avec succès");
    },
    handleError() {
      this.notifyError("Erreur lors de la mise à jour du profil");
    },
    async handleGoToProfile() {
      try {
        const query = new Parse.Query(Parse.User);
        query.equalTo("objectId", Parse.User.current().id);
        query.include("group");

        this.userProfileInformations = await query.first();
        this.blocSelected = "profile";
      } catch (error) {
        console.error("Erreur lors de la récupération du profil:", error);
      }
    },
    async ResetMenu() {
      console.log("Appel de reset-menu");
      this.editUser = false;
      this.createUserBoolean = false;
      this.LinksOfUser = [];
      await resetLinksGlobal();
      await resetIdPatientGlobal();
      this.blocSelected = "programmes";
      this.keyMenu++;
      this.tabAdd = [];
      this.tabRemove = [];
    },
    async handleIdUser(id) {
      this.objectIdPatient = id;
      this.blocSelected = "programmes";
      this.verifBfp();
      document.cookie =
        "idUserForSave=" + this.objectIdPatient + ";path=/;max-age=3600";
      this.keyHeader++;
    },
    handleEmitCreateUser() {
      this.blocSelected = "programmes";
      this.LinksOfUser = [];
      this.tabAdd = [];
      this.tabRemove = [];
      this.createUserBoolean = true;
      this.keyAttriBrique++;
      this.blocSelected = "createUser";
    },
    testIdPatient() {},
    notifySuccess(msg) {
      this.$refs.notifToast.showToast(msg, "success");
    },
    notifyError(msg) {
      this.$refs.notifToast.showToast(msg, "error");
    },
    handleUpdateUser(user) {
      this.isMenuClosed = false;
      this.createUserBoolean = false;
      this.editUser = true;
    },
    handleUnDisplayHeader(data) {
      this.isMenuClosed = true;
    },
    async handleSuiviData(user) {
      console.log("user-handleSuiviData", user);
      this.SuiviDataUser = await getSuiviForUser(user.objectId);
      this.IdSuiviDataUser = user.objectId;
      this.blocSelected = "suiviUtilisateur";
      // this.handleIsLoadingActivitySpace(false);
    },
    handleBackToActivitySpacePro() {
      this.isMenuClosed = false;
      this.editPro = false;
      const user = "activitySpace";
      this.blocSelected = "programmes";
      this.editUser = false;
      this.createUserBoolean = false;
      if (this.$refs.verticalMenu) {
        setTimeout(() => {
          this.$refs.verticalMenu.selectUser(user);
        }, 500);
      }
    },
    async generateToken() {
      AuthService.generateToken(Parse.User.current().id);
    },
    async generateSession() {
      AuthService.getSessionToken(Parse.User.current().attributes.loginToken);
    },
    handleIsLoadingActivitySpace(value) {
      console.log("isLoadingActivitySpace", value);
      this.isLoadingActivitySpace = value;
    },
    handleIdlinkUser(link) {
      this.LinksOfUser = link;
    },
    callSocketOnline() {
      try {
        const webSocketService = new WebSocketService();
        // Pour se connecter au WebSocket
        webSocketService.connectToWebSocket();
        // Pour envoyer l'ID de l'utilisateur
        const userId = Parse.User.current().id;
        webSocketService.sendUserId(userId);
      } catch (error) {
        console.error("Erreur lors de la connexion au WebSocket :", error);
      }
    },
    handleDisplayProfile() {},
    handleDisplayHeader() {
      this.keyHeader++;
    },
    handlePersonnalisation() {},
    handleReloadHeader(value) {
      this.keyHeader++;
    },
    handleClosePopUpContact() {
      this.showPopUpContact = false;
    },
    handleDisplayContactPopUp() {
      this.showPopUpContact = !this.showPopUpContact;
    },
    async verifBfp() {
      var userId =
        this.objectIdPatient || localStorage.getItem("idUserForSave");
      if (userId) {
        this.blocSelected = await this.checkUser(userId);
      } else {
        this.blocSelected = await this.checkUser(Parse.User.current().id);
      }
    },
    async checkUser(userId) {
      try {
        var user = await getUserById(userId);
        if (user.QuestionnaireBegin) {
          // Appel de la fonction Cloud findFilesDoneHome
          let results = await Parse.Cloud.run("findFilesDoneHome", {
            userId: userId,
          });
          console.log("Results:", results);
          if (results.length > 0 && Parse.User.current().attributes.cgu === true) {
            console.log("resultat supérieur à 0");
            return "programmes";
          } else if (Parse.User.current().attributes.cgu === true) {
            console.log("resultat inférieur à 0");
            return "beginPage3";
          } else {
            console.log("Questionnaire Begin No");
            return "beginPage";
          }
        } else if (Parse.User.current().attributes.cgu === true) {
          console.log("Questionnaire Begin No");
          return "programmes";
        } else {
          console.log("Questionnaire Begin No");
          return "beginPage";
        }
      } catch (error) {
        console.error(
          "Erreur lors de la vérification de l'utilisateur :",
          error
        );
        return "programmes";
      }
    },
    findFilesDone(user) {
      Parse.Cloud.run(
        "findFilesDoneHome",
        { userId: user.objectId },
        {
          success: function (results) {
            return results;
          },
          error: function (error) {
            console.error(
              "Erreur lors de la récupération des fichiers:",
              error
            );
            throw error;
          },
        }
      );
    },
    handleContinue() {
      this.blocSelected = "beginPage2";
    },
    recupParamMsg() {
      // console.log("recupParamJeu");
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      this.ParamMsg = urlParams.get("idUserMsg");
    },
    recupParamJeu() {
      // console.log("recupParamJeu");
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      this.ParamJeu = urlParams.get("jeu");
      // console.log("ParamJeu", this.ParamJeu);
    },
    recupParamJeuGuidee() {
      // console.log("recupParamJeuGuidee");
      const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
      // console.log("urlParams", urlParams);
      this.ParamJeuGuidee = urlParams.get("jeu_guidee");
      // console.log("ParamJeu", this.ParamJeuGuidee);
      this.SeanceSelected = urlParams.get("SeanceSelected");
      // console.log("SeanceSelected", this.SeanceSelected);
    },
    handleContinue2() {
      if (Parse.User.current().attributes.QuestionnaireBegin) {
        this.blocSelected = "beginPage3";
      } else {
        this.blocSelected = "programmes";
      }
    },
    popUpContactEmitted() {
      this.$refs.childRef.childMethod();
    },
    handleContinue3() {
      this.blocSelected = "programmes";
    },
    blocName(e) {
      this.blocSelected = e;
    },
    handleOpenModal() {
      this.modalKey++;
      this.showModalBienEtre = true;
      this.showModalTechnique = false;
    },
    handleOpenModalTechnique() {
      this.modalKey++;
      this.showModalTechnique = true;
      this.showModalBienEtre = false;
    },
    handleCloseModal() {
      this.showModalBienEtre = false;
      this.showModalTechnique = false;
    },
    onIsDisplayProgrammes(value) {
      this.isProgrammesDisplayed = value;
    },

    async checkIfAcknowledged() {
      const MajStatut = Parse.Object.extend("maj_user_statuts");
      const query = new Parse.Query(MajStatut);
      query.equalTo("user", Parse.User.current());
      query.equalTo("maj", this.maj[0]);

      try {
        const results = await query.first();
        if (results) {
          this.showNotification = false;
        }
      } catch (error) {
        console.log(
          "Erreur lors de la vérification du statut de la mise à jour:",
          error
        );
      }
    },
    async getLatestMaj() {
      try {
        Parse.Cloud.run(
          "getLatestMaj",
          {},
          {
            success: (results) => {
              this.maj = results;
              this.checkIfAcknowledged();
            },
            error: (error) => {
              console.error(
                "Erreur lors de la récupération des mises à jour:",
                error
              );
            },
          }
        );
      } catch (error) {
        console.error("Erreur lors de l'appel à getLatestMaj:", error);
      }
    },
    getFoldersByTheme(monTheme) {
      return this.foldersBrique.filter((el) => {
        if (el.themes.length < 2) {
          return el.themes[0].objectId === monTheme;
        }
        return (
          el.themes[0].objectId === monTheme ||
          el.themes[1].objectId === monTheme
        );
      });
    },
  },
};
</script>

<style>
html {
  max-height: 100% !important;
  max-width: 100% !important;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica Neue, sans-serif;
  background-color: #f3f3f3;
}
.container {
  min-height: 60vh;
  margin-bottom: -50px;
  padding-bottom: 50px;
}
@media (min-width: 1800px) {
  .container {
    min-height: 70vh;
  }
}

.main-content {
  flex: 1;
}

/* Styles du footer */
.sticky-footer {
  flex-shrink: 0;
}

.vertical-menu-container {
  border-left: 1px solid #ddd; /* Optionnel : ajoute une bordure à gauche du menu */
  position: relative;
  left: 0;
  top: 0;
  height: 100vh;
  background: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  min-width: fit-content;
  overflow-y: auto;
  max-height: 100vh;
  scrollbar-width: none; /* Pour Firefox */
  scrollbar-color: #888 #f1f1f1; /* Pour Firefox */
}

.menu-closed {
  transform: translateX(-100%);
  overflow-y: visible;
}

.menu-toggle {
  position: relative;
  margin-left: auto;
  top: 0px;
  cursor: pointer;
  z-index: 40;
  margin: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease;
}

.menu-toggle.closed {
  left: 20px; /* Le bouton reste visible quand le menu est fermé */
}

.bar {
  width: 25px;
  height: 3px;
  background: #333;
  margin: 5px 0;
  transition: transform 0.3s ease;
}

/* Animation pour transformer le bouton en X quand le menu est ouvert */
.menu-toggle.closed .bar:first-child {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle.closed .bar:last-child {
  transform: rotate(-45deg) translate(5px, -5px);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.loader-wrapper {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-text {
  color: #239380; /* Utilisation de votre code couleur */
  font-size: 2rem;
  font-weight: 500;
}

.menu-toggle {
  right: 0px;
  /* margin-left: auto; */
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

/* Barres de la croix */
.menu-toggle .bar {
  position: absolute;
  width: 20px;
  height: 4px;
  background-color: #333;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.menu-toggle .bar:first-child {
  transform: rotate(45deg);
}

.menu-toggle .bar:last-child {
  transform: rotate(-45deg);
}

.menu-toggle:hover {
  /*zoom*/
  transform: scale(1.1);
}

.menu-toggle:hover .bar {
  background-color: #239380;
}

/* Conteneur du bouton hamburger */
.hamburger {
  width: 40px;
  height: 30px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: transform 0.3s ease;
}

.hamburger:hover {
  transform: scale(1.1);
}

/* Barres du hamburger */
.hamburger .bar {
  width: 30px;
  height: 4px;
  background-color: #333;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.hamburger:hover .bar {
  background-color: #239380;
}

/* Transformation en croix lors de l'activation */
.hamburger.active .bar:nth-child(1) {
  transform: rotate(20deg) translate(2px, 2px);
}

.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.active .bar:nth-child(3) {
  transform: rotate(-20deg) translate(2px, -2px);
}

.vertical-menu-container verticalMenu {
  max-height: 0;
  transform: translateY(-20px);
  opacity: 0;
  transition: max-height 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
}

/* Styles lorsque le menu est ouvert */
.vertical-menu-container.menu-open verticalMenu {
  max-height: 500px; /* Ajuster cette valeur selon la taille de ton menu */
  transform: translateY(0);
  opacity: 1;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter {
  transform: translateX(-100%);
}

.slide-leave-to {
  transform: translateX(100%);
}
.floating-button {
  position: fixed;
  right: -20px; /* Commence légèrement en dehors de l'écran */
  top: 16%;
  transform: translateY(-50%);
  background: linear-gradient(135deg, #239380 0%, #1a7563 100%);
  padding: 12px 32px 12px 24px;
  border-radius: 50px 0 0 50px; /* Arrondi uniquement à gauche */
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: -4px 4px 15px rgba(35, 147, 128, 0.2);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 2px solid rgba(255, 255, 255, 0.1);
  z-index: 1000;
  animation: slideFromRight 0.6s forwards;
}

.floating-button:hover {
  right: -10px; /* Légère sortie au hover */
  transform: translateY(-50%) scale(1.02);
  box-shadow: -6px 4px 20px rgba(35, 147, 128, 0.3);
  background: linear-gradient(135deg, #1a7563 0%, #239380 100%);
}

.button-content {
  display: flex;
  align-items: center;
  gap: 12px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.floating-button:hover .button-content {
  transform: translateX(
    -5px
  ); /* Léger décalage du contenu vers la gauche au hover */
}

.button-content i {
  font-size: 20px;
  animation: NewBounce 2s infinite;
}

.button-text {
  white-space: nowrap;
  opacity: 0.9;
}

.pulse-ring {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px 0 0 50px;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes NewBounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(35, 147, 128, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(35, 147, 128, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(35, 147, 128, 0);
  }
}

@media (max-width: 768px) {
  .floating-button {
    padding: 10px 25px 10px 20px;
    right: -15px;
  }

  .button-content {
    font-size: 14px;
  }

  .button-content i {
    font-size: 18px;
  }
}

.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.marginMain {
  min-height: 100vh;
}
.main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.container-protocol {
  margin-bottom: 15px;
}
.content-container {
  overflow-y: auto;
  max-height: 100vh;
  scrollbar-width: auto; /* Pour Firefox */
  scrollbar-color: #239380 #fff; /* Pour Firefox */
  padding-bottom: 4rem;
}
/* Pour Webkit (Chrome, Safari, etc.) */
.content-container::-webkit-scrollbar {
  width: 8px;
}

.content-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.content-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.content-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.IndexNavbar {
  z-index: 1000;
}

.width100 {
  width: 100%;
}
</style>
