<template>
  <div v-if="isLoading">
    <div
      class="notification-delete"
      v-show="showDeleteNotification"
      :class="{ show: showDeleteNotification }"
    >
      Exercice supprimé
    </div>
    <!-- Loader -->
    <div class="loader-detail-relecture"></div>
  </div>
  <div v-else>
    <div v-if="typeExercice === 'Questionnaire'">
      <table class="table table-custom">
        <thead>
          <tr class="is-medium">
            <th class="ThDetail">Note</th>
            <th class="ThDetail">Date</th>
            <th class="ThDetail">Temps passé</th>
            <th v-if="isTherapeute" class="ThDetail">Détails</th>
            <th class="ThDetail">Action</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="result in fileDone.slice().reverse()">
            <tr :key="result.id">
              <td>{{ result.attributes.note }}</td>
              <td>
                {{ formatDate(result.attributes.createdAt) }}
              </td>
              <td>
                {{ formatTime(result) }}
              </td>
              <td v-if="isTherapeute" class="centered-button">
                <button
                  class="button is-small button-custom"
                  v-on:click="MoreInformationsQCM(result)"
                >
                  <i class="icon fas fa-info-circle"></i>
                  <p>détail</p>
                </button>
              </td>
              <td>
                <button
                  class="button is-small delete-button-custom"
                  v-on:click="deleteExercise(result)"
                >
                  <i class="icon fas fa-trash" style="color: red"></i>
                </button>
              </td>
            </tr>
            <tr
              v-if="detailsVisible === result.id"
              :key="result.id + '-details'"
              class="details-row"
            >
              <td :colspan="5">
                <p>
                  L'analyse de l'exercice :
                  <span
                    v-html="
                      result.attributes.questionResults[0].attributes.text
                    "
                  ></span>
                </p>
                <div class="PlacementTableauQuestionnaire">
                  <table
                    v-if="
                      result.attributes.resultatQuestionnaire &&
                      result.attributes.resultatQuestionnaire.length > 0
                    "
                    class="table is-bordered is-fullwidth"
                  >
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Réponse</th>
                        <th>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :key="result.id"
                        v-for="result in result.attributes
                          .resultatQuestionnaire"
                      >
                        <td>{{ result.question }}</td>
                        <td>{{ result.text }}</td>
                        <td>{{ result.note }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-else-if="this.typeExercice === 'Module Expert'">
      <button
        class="chart-button-fullscreen"
        v-on:click="toggleChartVisibility"
      >
        {{ showChart ? "Cacher le graphique" : "Afficher le graphique" }}
      </button>
      <div v-if="showChart && scores.length > 1" id="chartResultIdDetails">
        <button
          class="chart-button-fullscreen my-button"
          v-on:click="toggleFullScreen()"
        >
          Afficher en plein écran le graphique
        </button>
        <button
          class="chart-button-fullscreen my-button"
          v-on:click="printChart()"
        >
          Imprimer le graphique
        </button>
        <chart-result :scores="scores" :dates="dates" :key="indexChartResult" />
      </div>
      <table class="table is-fullwidth table-custom">
        <thead>
          <tr class="is-medium">
            <th class="ThDetail">Date</th>
            <th class="ThDetail">Temps passé</th>
            <th class="ThDetail">Détails</th>
            <th class="ThDetail">Action</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="result in fileDone.slice()">
            <tr :key="result.id">
              <td>
                {{ formatDate(result.attributes.createdAt) }}
              </td>
              <td>{{ formatTime(result) }}</td>
              <td class="centered-button">
                <button
                  class="button is-small button-custom"
                  v-on:click="MoreInformations(result)"
                >
                  <i class="icon fas fa-info-circle"></i>
                  <p>détail</p>
                </button>
              </td>
              <td>
                <button
                  class="button is-small delete-button-custom"
                  v-on:click="deleteExercise(result)"
                >
                  <i class="icon fas fa-trash" style="color: red"></i>
                </button>
              </td>
            </tr>
            <!-- <button @click="test(result)">test</button> -->
            <tr
              v-if="detailsVisible === result.id"
              :key="result.id + '-details'"
              class="details-row"
            >
              <td :colspan="5">
                <relecture-moniteur
                  @commentaire-change="handleCommentaireChange"
                  @save-change="saveCommentaireChange"
                  class="ComponentRelecture"
                  :valeurX="valeurX"
                  :fcMoyenne="BpmCC"
                  :ccMoyenne="AverageCC"
                  :couleurCCMoyenne="couleurCCMoyenne"
                  :TabRowFC="valeurY"
                  :DefaultOpenedDetails="DefaultOpenedDetails"
                  :TabRowCC="valeurCCY"
                  :ValeurCCX="valeurCCX"
                  :labelChrono="labelChrono"
                  :timeElapsed="timeElapsed"
                  :comment="comment"
                  :Pct1="Pct1"
                  :Pct2="Pct2"
                  :Pct3="Pct3"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div
      v-else-if="this.typeExercice === 'Module Expert : Indice de lâcher-prise'"
    >
      <button
        class="chart-button-fullscreen"
        v-on:click="toggleChartVisibility"
      >
        {{ showChart ? "Cacher le graphique" : "Afficher le graphique" }}
      </button>
      <div v-if="showChart && scores.length > 1" id="chartResultIdDetails">
        <button
          class="chart-button-fullscreen my-button"
          v-on:click="toggleFullScreen()"
        >
          Afficher en plein écran le graphique
        </button>
        <button
          class="chart-button-fullscreen my-button"
          v-on:click="printChart()"
        >
          Imprimer le graphique
        </button>
        <chart-result :scores="scores" :dates="dates" />
      </div>
      <table class="table is-fullwidth table-custom">
        <thead>
          <tr class="is-medium">
            <th class="ThDetail">Date</th>
            <th class="ThDetail">Temps passé</th>
            <th class="ThDetail">Détails</th>
            <th class="ThDetail">Action</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="result in fileDone.slice().reverse()">
            <tr :key="result.id">
              <td>
                {{ formatDate(result.attributes.createdAt) }}
              </td>
              <td>{{ formatTime(result) }}</td>
              <td class="centered-button">
                <button
                  class="button is-small button-custom"
                  v-on:click="MoreInformations(result)"
                >
                  <i class="icon fas fa-info-circle"></i>
                  <p>détail</p>
                </button>
              </td>
              <td>
                <button
                  class="button is-small delete-button-custom"
                  v-on:click="deleteExercise(result)"
                >
                  <i class="icon fas fa-trash" style="color: red"></i>
                </button>
              </td>
            </tr>
            <!-- <button @click="test(result)">test</button> -->
            <tr
              v-if="detailsVisible === result.id"
              :key="result.id + '-details'"
              class="details-row"
            >
              <td :colspan="5">
                <AppelRelectureAniVue
                  @commentaire-change="handleCommentaireChange"
                  @save-change="saveCommentaireChange"
                  class="ComponentRelecture"
                  :valeurX="valeurX"
                  :fcMoyenne="BpmCC"
                  :ccMoyenne="AverageCC"
                  :couleurCCMoyenne="couleurCCMoyenne"
                  :TabRowFC="valeurY"
                  :DefaultOpenedDetails="DefaultOpenedDetails"
                  :TabRowCC="valeurCCY"
                  :ValeurCCX="valeurCCX"
                  :labelChrono="labelChrono"
                  :timeElapsed="timeElapsed"
                  :comment="comment"
                  :dateCreated="dateCreated"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div v-else-if="this.typeExercice === 'Jeux'">
      <button
        class="chart-button-fullscreen"
        v-on:click="toggleChartVisibility"
      >
        {{ showChart ? "Cacher le graphique" : "Afficher le graphique" }}
      </button>
      <div v-if="showChart && scores.length > 1" id="chartResultIdDetails">
        <button
          class="chart-button-fullscreen my-button"
          v-on:click="toggleFullScreen()"
        >
          Afficher en plein écran le graphique
        </button>
        <button
          class="chart-button-fullscreen my-button"
          v-on:click="printChart()"
        >
          Imprimer le graphique
        </button>
        <chart-result :scores="scores" :dates="dates" />
      </div>
      <table class="table is-hoverable is-fullwidth table-custom">
        <thead>
          <tr class="is-medium">
            <th class="ThDetail">Date</th>
            <th class="ThDetail">Temps passé</th>
            <th class="ThDetail">Détails</th>
            <th class="ThDetail">Action</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="result in fileDone.slice()">
            <tr :key="result.id">
              <td>
                {{ formatDate(result.attributes.createdAt) }}
              </td>
              <td>{{ formatTime(result) }}</td>
              <td class="centered-button">
                <button
                  class="button is-small button-custom"
                  v-on:click="MoreInformations(result)"
                >
                  <i class="icon fas fa-info-circle"></i>
                  <p>détail</p>
                </button>
              </td>
              <td>
                <button
                  class="button is-small delete-button-custom"
                  v-on:click="deleteExercise(result)"
                >
                  <i class="icon fas fa-trash" style="color: red"></i>
                </button>
              </td>
            </tr>
            <tr
              v-if="detailsVisible === result.id"
              :key="result.id + '-details'"
              class="details-row"
            >
              <td :colspan="5">
                <relecture-moniteur
                  @commentaire-change="handleCommentaireChange"
                  @save-change="saveCommentaireChange"
                  class="ComponentRelecture"
                  :valeurX="valeurX"
                  :fcMoyenne="BpmCC"
                  :ccMoyenne="AverageCC"
                  :couleurCCMoyenne="couleurCCMoyenne"
                  :TabRowFC="valeurY"
                  :DefaultOpenedDetails="DefaultOpenedDetails"
                  :TabRowCC="valeurCCY"
                  :ValeurCCX="valeurCCX"
                  :labelChrono="labelChrono"
                  :timeElapsed="timeElapsed"
                  :comment="comment"
                  :Pct1="Pct1"
                  :Pct2="Pct2"
                  :Pct3="Pct3"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { fetchRelectureData } from "@/services/relecture/relectureService";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import RelectureMoniteur from "./relectureMoniteur.vue";
// import AppelRelectureAni from "@/components/AppelRelectureAni.vue";
import AppelRelectureAniVue from "../relecture/relectureDetailsAni.vue";
import ChartResult from "../chart/chartResultComparaison.vue";

import Parse from "parse";
export default {
  data() {
    return {
      file: null,
      fileDone: null,
      user: null,
      detailsVisible: null,
      isLoading: true, // Ajout de la variable isLoading pour gérer le loader
      valeurX: 0,
      BpmCC: 0,
      AverageCC: 0,
      couleurCCMoyenne: "#FE0000",
      valeurY: 0,
      valeurCCX: 0,
      valeurCCY: 0,
      labelChrono: 0,
      timeElapsed: 0,
      comment: "",
      commentaireMoniteur: "",
      filesDoneSelectedId: "",
      showNotification: false,
      notificationType: "",
      notificationMessage: "",
      Pct1: 0,
      Pct2: 0,
      Pct3: 0,
      isTherapeute: false,
      showDeleteNotification: false,
      scores: [],
      dates: [],
      dateCreated: null,
      showChart: true,
      indexChartResult: 0,
    };
  },
  components: {
    RelectureMoniteur,
    // AppelRelectureAni,
    AppelRelectureAniVue,
    ChartResult,
  },
  props: {
    fileDoneId: {
      type: String,
      required: true,
    },
    selectedUser: {
      type: Object,
      required: true,
    },
    typeExercice: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleCommentaireChange(commentaire) {
      this.commentaireANI = commentaire;
    },
    test(result) {
      console.log(result);
      var resultatJeux = JSON.parse(result.attributes.resultatJeux);
      console.log(resultatJeux);
      var AverageCC = resultatJeux.AverageCC;
      console.log(AverageCC);
    },
    toggleFullScreen() {
      let element = document.getElementById("chartResultIdDetails");
      if (!document.fullscreenElement) {
        element.requestFullscreen().catch((err) => {
          alert(
            `Erreur lors de la tentative de passage en plein écran: ${err.message} (${err.name})`
          );
        });
        this.isFullScreen = true; // Mettre à jour l'état en plein écran
      } else {
        document.exitFullscreen();
        this.isFullScreen = false; // Mettre à jour l'état lors de la sortie du plein écran
        this.indexChartResult++;
      }
    },
    exitFullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.isFullScreen = false; // Mettre à jour l'état lors de la sortie du plein écran
        this.indexChartResult++;
      }
    },

    async printChart() {
      const element = document.getElementById("chartResultIdDetails");

      // Ajoutez la classe 'no-print' aux éléments à cacher
      const buttons = element.querySelectorAll(".my-button");
      buttons.forEach((btn) => btn.classList.add("no-print"));

      const canvas = await html2canvas(element);

      // Création et sauvegarde du PDF comme avant
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        0,
        0,
        canvas.width,
        canvas.height
      );
      pdf.save("resultat.pdf");

      // Retirez la classe 'no-print' après la création du PDF
      buttons.forEach((btn) => btn.classList.remove("no-print"));
    },
    saveCommentaireChange() {
      const queryFileDone = new Parse.Query("file_done");
      queryFileDone.equalTo("objectId", this.filesDoneSelectedId);
      queryFileDone.first().then((fileDone) => {
        fileDone.set("comment", this.commentaireANI);
        fileDone.save().then((result) => {
          console.log("Commentaire enregistré avec succès", result);
          this.showNotification = true;
          this.notificationType = "is-success"; // ou "is-danger" pour une notification d'erreur
          this.notificationMessage =
            "Le commentaire a été enregistré avec succès.";
        });
      });
    },
    toggleChartVisibility() {
      this.showChart = !this.showChart;
    },
    async deleteExercise(result) {
      var fileDone = Parse.Object.extend("file_done");
      var query = new Parse.Query(fileDone);
      query.get(result.id).then(async (fileDone) => {
        await fileDone.destroy();
        this.showDeleteNotification = true; // Ajout de la propriété showDeleteNotification pour afficher la notification
        this.isLoading = true;
        var id = this.$IdPatient || Parse.User.current().id;
        const response = await fetchRelectureData(
          this.fileDoneId,
          id,
          this.typeExercice
        );
        this.isLoading = false;
        this.fileDone = response.fileDone;
        this.showNotificationFunction();
      });
    },
    formatTime(value) {
      if (
        value &&
        value.attributes.file &&
        value.attributes.file.attributes.type &&
        value.attributes.file.attributes.type.attributes.name === "Jeux"
      ) {
        return `${value.attributes.time_elapsed} min`;
      } else {
        var seconds = value.attributes.time_elapsed;
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        let timeString = "";
        if (hrs > 0) {
          timeString += `${hrs} h `;
        }
        if (mins > 0) {
          timeString += `${mins} min `;
        }
        if (secs > 0) {
          timeString += `${secs} sec`;
        }
        return timeString;
      }
    },
    formatDate(date) {
      return format(new Date(date), "dd/MM/yyyy à HH:mm:ss", { locale: fr });
    },
    showNotificationFunction() {
      this.showDeleteNotification = true;
      setTimeout(() => {
        this.showDeleteNotification = false;
      }, 5000);
    },
    MoreInformationsQCM(result) {
      this.detailsVisible =
        this.detailsVisible === result.id ? null : result.id;
    },
    MoreInformations(result) {
      this.filesDoneSelectedId = result.id;
      this.dateCreated = result.attributes.createdAt;

      let parsedResultatJeux = null; // Variable pour stocker la valeur analysée

      if (result.attributes.resultatJeux) {
        parsedResultatJeux = JSON.parse(result.attributes.resultatJeux);
        if (parsedResultatJeux.ArrayTacho) {
          // Utilisez la méthode map pour extraire tous les x et diviser par 500 pour obtenir le temps en secondes
          this.valeurX = parsedResultatJeux.ArrayTacho.map(
            (item) => item.x / 1000
          );
          //arrondir à l'unité
          this.valeurX = this.valeurX.map((item) => Math.round(item));

          this.valeurY = parsedResultatJeux.ArrayTacho.map((item) => item.y);
        }
        if (parsedResultatJeux.TabCC) {
          this.valeurCCX = parsedResultatJeux.TabCC.map(
            (item) => item.x / 1000
          );
          this.valeurCCX = this.valeurCCX.map((item) => Math.round(item));
          this.valeurCCY = parsedResultatJeux.TabCC.map((item) => item.y);
        }
        this.Pct1 = parsedResultatJeux.Pct1;
        this.Pct2 = parsedResultatJeux.Pct2;
        this.Pct3 = parsedResultatJeux.Pct3;
        this.BpmCC = parsedResultatJeux.BpmCC;
        this.AverageCC = parsedResultatJeux.AverageCC;
      }

      this.labelChrono = result.attributes.timeCalibrage;
      this.timeElapsed = result.attributes.time_elapsed;
      this.comment = result.attributes.comment;
      if (this.AverageCC >= 85) {
        this.couleurCCMoyenne = "#51C901";
      } else if (this.AverageCC >= 60) {
        this.couleurCCMoyenne = "#FFC801";
      } else {
        this.couleurCCMoyenne = "#FE0000";
      }
      this.detailsVisible =
        this.detailsVisible === result.id ? null : result.id;
    },
  },
  async created() {
    if (
      this.selectedUser.therapeute ||
      Parse.User.current().attributes.therapeute
    ) {
      this.isTherapeute = true;
    }
    try {
      var id = this.$IdPatient || Parse.User.current().id;
      const { fileDone } = await fetchRelectureData(
        this.fileDoneId,
        id,
        this.typeExercice
      );
      this.fileDone = fileDone;
      console.log("Voici les files dones reçus:", fileDone);
      //trier les jeux par date
      fileDone.sort((a, b) => {
        return (
          new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
        );
      });
      console.log("Voici les files dones triés:", fileDone);
      //créer une boucle pour récupérer les scores et les dates
      fileDone.forEach((result) => {
        if (result.attributes.resultatJeux) {
          var Resultat = JSON.parse(result.attributes.resultatJeux);
          var AverageCC = Resultat.AverageCC;
          this.scores.push(AverageCC);
          this.dates.push(
            format(new Date(result.attributes.createdAt), "dd/MM/yyyy")
          );
          //range dans l'odre des dates du plus vieux au plus récents
          const combined = this.scores.map((score, index) => ({
            score: score,
            date: this.dates[index],
          }));

          // Sort combined array by date (assuming dates are in "dd/MM/yyyy" format)
          combined.sort((a, b) => {
            const [dayA, monthA, yearA] = a.date.split("/").map(Number);
            const [dayB, monthB, yearB] = b.date.split("/").map(Number);
            const dateA = new Date(yearA, monthA - 1, dayA);
            const dateB = new Date(yearB, monthB - 1, dayB);
            return dateA - dateB;
          });

          // Extract sorted scores and dates back into separate arrays
          this.scores = combined.map((item) => item.score);
          this.dates = combined.map((item) => item.date);
        }
      });
      this.isLoading = false; // On passe isLoading à false pour cacher le loader
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
<style>
.ThDetail {
  font-size: 0.8rem;
  font-weight: 600;
  color: white !important;
  background-color: #20948b;
  border-color: #20948b;
}
.loader-detail-relecture {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #20948b;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.resultat-questionnaire {
  padding: 1rem;
}

.resultat-questionnaire ul {
  list-style: none;
  padding: 0;
}

.resultat-questionnaire li {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.table-custom {
  border-collapse: separate;
  background-color: #f5f5f5;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  border-radius: 6px;
  font-family: Arial, sans-serif;
  width: 100%;
}

.table-custom thead {
  background-color: rgb(33, 137, 119);
  color: #fff;
}

.table-custom thead th {
  border-bottom: 2px solid #dbdbdb;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1.25rem;
}

.table-custom tbody tr {
  border-radius: 6px;
  background-color: #fff;
}

.table-custom tbody tr:hover {
  background-color: #e8e8e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.button-custom {
  background-color: #f1c40f !important;
  border-color: transparent;
  color: #fff !important;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}
.delete-button-custom {
  border-color: transparent;
  color: #fff !important;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}
.centered-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-custom:hover {
  background-color: #d4ac0d !important;
  color: #fff !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.icon {
  margin-right: 0.25rem;
}
.table-custom tbody td {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  vertical-align: middle;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.PlacementTableauQuestionnaire {
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 50px;
}
.button.is-warning {
  background-color: #ffcc00;
  border-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
}

.button.is-warning:hover {
  background-color: #e6b800;
  color: #fff;
}

.icon {
  margin-right: 0.25rem;
}
tr.details-row {
  background-color: #f9f9f9;
  font-size: 14px;
}

tr.details-row td {
  padding: 10px;
  text-align: center;
}

tr.details-row td:first-child {
  /* font-weight: bold; */
}

tr.details-row p {
  margin-bottom: 10px;
}

tr.details-row span {
  /* font-weight: bold; */
}
table-title-suivi {
  font-size: 24px;
  margin-bottom: 16px;
}
.notification-delete {
  padding: 20px;
  background-color: #4caf50; /* Green */
  color: white;
  position: fixed;
  z-index: 1;
  top: 30px;
  right: 30px;
  border-radius: 5px;
  border-left: 8px solid #388e3c; /* Darker green */
  transition: opacity 0.6s; /* Fade-out effect */
  opacity: 0; /* Start hidden */
}

.notification-delete.show {
  opacity: 1; /* Become visible */
}

.no-print {
  display: none !important;
}

.chart-button-fullscreen {
  background-color: #239380; /* Green background */
  color: white; /* White text */
  padding: 6px 12px; /* Padding around the text */
  font-size: 12px; /* Button text size */
  border: none; /* No borders */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
  display: inline-flex; /* Flexbox for inline alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center text and icon horizontally */
  margin-bottom: 1rem; /* Add some space below the button */
  margin-left: auto;
  display: flex;
  margin-right: 1rem;
  margin-top: 1rem;
}

#chartResultIdDetails {
  background-color: white;
}
</style>
