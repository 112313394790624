// globalProperties.js
export const GlobalProperties = {
    install(Vue) {
      const properties = {
        $Therapeute: false,
        $EssaiGratuit: false,
        $AccountLimited: false,
        $IsTherapeuteWithoutIdPatient: false,
        $NewVersionEnabled: false,
        $IdPatient: null
      };
  
      // Définir les propriétés globales
      Object.entries(properties).forEach(([key, value]) => {
        Vue.prototype[key] = value;
      });
  
      // Ajouter une méthode utilitaire pour mettre à jour les propriétés
      Vue.prototype.$setGlobalProperty = function(key, value) {
        if (key in properties) {
          Vue.prototype[key] = value;
        }
      };
    }
  };