import Parse from "parse";
import ParseConfig from "@/api/parse.config";

export async function getNewFoldersByType(type, userCourant, typeAbonnement) {
  ParseConfig();
  
  try {
    const folders = await Parse.Cloud.run("getNewFoldersByType", {
      type,
      typeAbonnement
    });
    console.log('NEW FOLDERS:',folders)
    return folders.result || folders;
  } catch (error) {
    console.error("Erreur lors de la récupération des dossiers:", error);
    throw error;
  }
}