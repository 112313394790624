<template>
    <div class="container mx-auto p-4">
      <h2 class="text-2xl font-bold mb-4">SymbioEngine Signal Receiver</h2>
      
      <!-- Status de connexion -->
      <div 
        :class="[
          'p-3 mb-4 rounded',
          isConnected ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
        ]"
      >
        {{ connectionStatus }}
      </div>
  
      <!-- Signaux Reçus -->
      <div class="mb-4" v-if="isAuthenticated">
        <h3 class="text-xl font-semibold mb-2">Derniers Signaux Reçus</h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <!-- Signal RR -->
          <div class="p-4 bg-gray-100 rounded">
            <h4 class="font-bold text-lg mb-2">Signal RR</h4>
            <div class="space-y-2">
              <p><strong>Dernière valeur :</strong> {{ lastRR || 'Aucun signal' }}</p>
              <p><strong>Reçu à :</strong> {{ lastRRTime || '-' }}</p>
            </div>
          </div>
          
          <!-- Signal ANI -->
          <div class="p-4 bg-gray-100 rounded">
            <h4 class="font-bold text-lg mb-2">Signal ANI</h4>
            <div class="space-y-2">
              <p><strong>Dernière valeur :</strong> {{ lastANI || 'Aucun signal' }}</p>
              <p><strong>Reçu à :</strong> {{ lastANITime || '-' }}</p>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Historique -->
      <div class="mt-4">
        <h3 class="text-xl font-semibold mb-2">Historique</h3>
        <div class="h-64 overflow-y-auto bg-gray-800 text-green-400 p-4 rounded font-mono text-sm">
          <div v-for="(log, index) in signalHistory" :key="index">
            {{ log }}
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import io from 'socket.io-client';
  
  export default {
    name: 'SignalReceiver',
    
    props: {
      username: {
        type: String,
        required: true,
        default: 'l.hanquiez22@gmail.com',
      }
    },
    
    data() {
      return {
        socket: null,
        isConnected: false,
        isAuthenticated: false,
        connectionStatus: 'En attente de connexion...',
        lastRR: null,
        lastRRTime: null,
        lastANI: null,
        lastANITime: null,
        signalHistory: []
      }
    },
  
    created() {
      this.initializeSocket();
    },
  
    methods: {
      addToHistory(message) {
        const timestamp = new Date().toLocaleTimeString();
        this.signalHistory.unshift(`[${timestamp}] ${message}`);
        
        if (this.signalHistory.length > 100) {
          this.signalHistory.pop();
        }
      },
  
      initializeSocket() {
        try {
          // Configuration Socket.IO v2.1.1
          this.socket = io('http://192.168.1.105:8080', {
            transports: ['websocket', 'polling'],
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            forceNew: true,
            query: {
              EIO: 3 // Spécifique à Socket.IO v2
            }
          });
  
          this.addToHistory('Tentative de connexion...');
  
          // Connexion
          this.socket.on('connect', () => {
            console.log('Socket connected');
            this.isConnected = true;
            this.connectionStatus = 'Connecté, authentification en cours...';
            this.addToHistory('Connexion établie');
            
            // Émission de l'authentification
            this.socket.emit('authentication', { 
              username: this.username 
            });
          });
  
          // Authentification réussie
          this.socket.on('authenticated', () => {
            console.log('Socket authenticated');
            this.isAuthenticated = true;
            this.connectionStatus = 'Authentifié avec succès';
            this.addToHistory('Authentification réussie');
          });
  
          // Erreur d'authentification
          this.socket.on('unauthorized', (error) => {
            console.error('Authentication failed:', error);
            this.isAuthenticated = false;
            this.connectionStatus = 'Échec de l\'authentification';
            this.addToHistory(`Erreur d'authentification: ${error.message}`);
          });
  
          // Déconnexion
          this.socket.on('disconnect', (reason) => {
            console.log('Socket disconnected:', reason);
            this.isConnected = false;
            this.isAuthenticated = false;
            this.connectionStatus = 'Déconnecté du serveur';
            this.addToHistory(`Déconnexion du serveur: ${reason}`);
          });
  
          // Erreur de connexion
          this.socket.on('connect_error', (error) => {
            console.error('Connection error:', error);
            this.isConnected = false;
            this.connectionStatus = `Erreur de connexion: ${error.message}`;
            this.addToHistory(`Erreur de connexion: ${error.message}`);
          });
  
          // Réception des signaux
          this.socket.on('rr', (data) => {
            console.log('RR signal received:', data);
            this.lastRR = data;
            this.lastRRTime = new Date().toLocaleTimeString();
            this.addToHistory(`Signal RR reçu: ${data}`);
          });
  
          this.socket.on('ani', (data) => {
            console.log('ANI signal received:', data);
            this.lastANI = data;
            this.lastANITime = new Date().toLocaleTimeString();
            this.addToHistory(`Signal ANI reçu: ${data}`);
          });
  
          // Gestion des reconnexions
          this.socket.on('reconnecting', (attemptNumber) => {
            console.log('Reconnection attempt:', attemptNumber);
            this.addToHistory(`Tentative de reconnexion #${attemptNumber}`);
          });
  
          this.socket.on('reconnect_failed', () => {
            console.log('Reconnection failed');
            this.addToHistory('Échec de la reconnexion');
          });
  
        } catch (error) {
          console.error('Socket initialization error:', error);
          this.connectionStatus = `Erreur d'initialisation: ${error.message}`;
          this.addToHistory(`Erreur d'initialisation: ${error.message}`);
        }
      }
    },
  
    beforeDestroy() { // Utilisez beforeDestroy au lieu de beforeUnmount pour Vue 2
      if (this.socket) {
        this.socket.disconnect();
        this.addToHistory('Déconnexion (composant démonté)');
      }
    }
  }
  </script>