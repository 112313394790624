<template>
  <div>
    <div class="subscribe-banner">
      <div class="banner-content">
        <span class="banner-text"
          >Profitez d'un essai gratuit de 15 jours pour découvrir nos outils
          dédiés à votre pratique !</span
        >
        <button class="banner-button" @click="goToSuscribe">
          Je profite !
        </button>
      </div>
    </div>
    <div v-if="isLoading" class="loading-overlay-connexion">
      <div class="loading-content-connexion">
        <div class="loading-spinner-connexion"></div>
        <p class="loading-text-connexion">Connexion en cours...</p>
      </div>
    </div>

    <div class="main-connexion">
      <div class="connexion-content">
        <div class="column is-4 is-offset-4 is-offset-2-mobile is-8-mobile">
          <a
            class="card-content is-flex is-horizontal-center"
            href="https://symbiocenter.fr/"
            target="_blank"
          >
            <img :src="logo.src" alt="Logo Symbiofi" loading="lazy" />
          </a>
        </div>
        <div class="">
          <div class="has-text-centered"></div>
          <div
            class="column is-offset-4-desktop is-three-fifths-tablet is-offset-one-fifth-tablet is-4-desktop"
            v-if="!isMaintenance"
          >
            <!-- <button @click="generateToken">generateToken</button>
            <button @click="recupToken">recup token</button> -->

            <div class="banner-notification" v-if="!emailValidated">
              <p>Veuillez d'abord entrer votre email.</p>
            </div>
            <div
              class="banner-notification"
              v-if="emailValidated && !userHasAcceptedCGU"
            >
              <p>
                C'est votre première connexion, vous devez créer votre mot de
                passe.
              </p>
            </div>
            <div class="has-text-centered is-margin-10">
              <div class="">
                <form @submit.prevent="connexion">
                  <div class="field has-addons has-addons-right">
                    <div class="control is-expanded">
                      <input
                        class="input is-large"
                        type="email"
                        placeholder="Email"
                        v-model="email"
                        @input="verifyEmail"
                        autocomplete="email"
                      />
                    </div>
                  </div>
                  <p
                    v-if="errorMail && !errorMailCabinet"
                    class="has-text-weight-light has-text-danger is-size-6"
                  >
                    Cet email n'est pas reconnu. Assurez-vous d'avoir entré un
                    email valide.
                  </p>

                  <p
                    v-else-if="!errorMail && errorMailCabinet"
                    class="has-text-weight-light has-text-danger is-size-6"
                  >
                    Vous possédez un compte cabinet, veuillez vous renseigner
                    auprès de votre professionnel.
                  </p>
                  <p
                    v-if="errorLogIn"
                    class="has-text-weight-light has-text-danger is-size-6"
                  >
                    Vos identifiants sont incorrects. Veuillez réessayer.
                  </p>
                  <p
                    v-if="!userHasValidAccount"
                    class="has-text-weight-light has-text-danger is-size-6"
                  >
                    Votre compte n'est pas encore validé, veuillez cliquer sur
                    le lien reçu par mail.
                  </p>
                  <p
                    v-if="errorAbonnementExpire"
                    class="has-text-weight-light has-text-danger is-size-6"
                  >
                    Votre abonnement a expiré. Veuillez contacter votre
                    thérapeute pour renouveler votre abonnement.
                  </p>
                  <!-- {{errorMailCabinet + ' ' + errorMail + ' ' + errorLogIn}} -->
                  <!-- Transition animée pour l'affichage des champs de mot de passe -->
                  <transition name="fade">
                    <div>
                      <div v-if="emailValidated">
                        <!-- Si l'utilisateur n'a pas accepté les CGU, afficher deux champs pour changer le mot de passe -->
                        <div v-if="!userHasAcceptedCGU">
                          <b-field>
                            <b-input
                              type="password"
                              placeholder="Nouveau mot de passe"
                              icon-pack="fas"
                              v-model="newPassword"
                              password-reveal
                              size="is-large"
                            ></b-input>
                          </b-field>
                          <b-field>
                            <b-input
                              type="password"
                              placeholder="Confirmer le mot de passe"
                              icon-pack="fas"
                              v-model="confirmPassword"
                              password-reveal
                              size="is-large"
                            ></b-input>
                          </b-field>
                          <p
                            v-if="passwordsDoNotMatch"
                            class="has-text-weight-light has-text-danger is-size-6"
                          >
                            Les mots de passe ne correspondent pas.
                          </p>
                        </div>
                        <!-- Si les CGU sont acceptées, afficher le champ classique pour entrer le mot de passe -->
                        <div v-else>
                          <b-field>
                            <b-input
                              type="password"
                              placeholder="Mot de passe"
                              icon-pack="fas"
                              v-model="password"
                              password-reveal
                              size="is-large"
                            ></b-input>
                          </b-field>
                        </div>
                      </div>
                      <button
                        :disabled="isFormDisabled"
                        type="submit"
                        class="button is-primary is-fullwidth is-large buttonConnexion"
                      >
                        {{ $t("CONNEXION") }}
                      </button>
                    </div>
                  </transition>
                </form>
              </div>
              <p style="margin-top: 10px">
                <router-link :to="'/reinitialisation-mot-de-passe'">
                  {{ $t("motDePasseOublie") }}
                </router-link>
              </p>
            </div>
          </div>
          <!--Maintenance-->
          <div v-else>
            <div class="column is-6 is-offset-3 has-text-centered">
              <div class="box">
                <p>NOTRE SITE EST EN MAINTENANCE POUR LE MOMENT</p>
                <p>
                  mais nous mettons tout en oeuvre pour que vous le retrouviez
                  très vite.
                </p>
                <p class="title has-text-grey is-size-6 has-text-centered">
                  {{ $t("") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footerM">
        <div class="footer-connexion is-fullwidth">
          <a
            class="button is-blue is-margin-10"
            href="https://www.symbiocenter.com/assets/Mentions_legales.pdf"
            target="_blank"
            ><u>Mentions légales</u></a
          >
          <a
            class="button is-blue is-margin-10"
            href="https://www.symbiofi.com"
            target="_blank"
            ><u>Centre de formation</u></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import { getObjectById } from "@/services/parse/getObject.js";
import AuthService from "@/services/utilisateurs/authService.js"; // Assurez-vous du bon chemin d'import

import { resetIdPatientGlobal } from "@/services/utilisateurs/globalSettingsUser.js";

const User = createNamespacedHelpers("user");

export default {
  name: "login",
  data() {
    return {
      isMaintenance: false,
      email: "",
      password: "",
      newPassword: "", // Nouveau mot de passe pour CGU non acceptées
      confirmPassword: "", // Confirmation du nouveau mot de passe
      emailValidated: false,
      userHasAcceptedCGU: false, // Flag pour vérifier l'acceptation des CGU
      emailLoading: false,
      errorMail: false,
      passwordsDoNotMatch: false,
      logo: {
        src: require("../../../assets/img/logo_symbiofi.png"),
      },
      SessionToken: null,
      isLoading: false, // Initialement en mode chargement
      errorMailCabinet: false,
      errorLogIn: false,
      userHasValidAccount: true,
      errorAbonnementExpire: false, // Ajout de la variable pour l'erreur d'abonnement expiré
    };
  },
  computed: {
    isFormDisabled() {
      if (!this.emailValidated) return true;
      if (!this.userHasAcceptedCGU) {
        return !this.newPassword || this.newPassword !== this.confirmPassword;
      }
      return !this.password;
    },
  },
  created() {
    resetIdPatientGlobal();
    try {
      this.recupToken();
    } catch (error) {
      console.log("Erreur lors de la récupération du token", error);
    }
  },
  methods: {
    ...User.mapActions({
      actionsLogIn: "NewLogIn",
    }),
    async generateToken() {
      AuthService.generateToken();
    },
    async recupToken() {
      try {
        var response = await AuthService.recupToken();
        console.log("Réponse:", response);
        this.SessionToken = response.tokenResponse;
        this.MailResponse = response.MailResponse;
        this.isLoading = true;
        var result = await this.actionsLogIn({
          SessionToken: this.SessionToken,
          MailResponse: this.MailResponse,
        });
        console.log("result", result);
      } catch (error) {
        console.log("Erreur lors de la récupération du token", error);
        // Vérifier si l'erreur est liée à un abonnement expiré
        if (error.message && error.message.includes("abonnement a expiré")) {
          this.errorAbonnementExpire = true;
        }
      } finally {
        this.isLoading = false; // Masquer le loader lorsque le chargement est terminé
      }
    },
    async verifyEmail() {
      if (!this.email) return;

      this.emailLoading = true;
      ParseConfig();

      try {
        const result = await Parse.Cloud.run("verifyUserEmail", {
          email: this.email,
        });
        console.log("result", result);
        this.emailValidated = result.emailValidated;
        this.errorMail = result.errorMail;

        if (result.emailValidated) {
          this.userHasAcceptedCGU = result.userHasAcceptedCGU;
          this.idUser = result.idUser;
          this.userHasValidAccount = result.userHasValidAccount;
          this.errorMailCabinet = result.errorMailCabinet;
        }
      } catch (error) {
        console.error("Error verifying email:", error);
        this.emailValidated = false;
        this.errorMail = true;
      } finally {
        this.emailLoading = false;
      }
    },

    async connexion() {
      if (this.emailValidated) {
        try {
          this.isLoading = true;
          this.errorAbonnementExpire = false; // Réinitialiser l'erreur d'abonnement expiré

          console.log("Connexion...");
          if (!this.userHasAcceptedCGU) {
            console.log("Nouveau mot de passe");
            if (this.newPassword !== this.confirmPassword) {
              this.passwordsDoNotMatch = true;
              return;
            }
            this.passwordsDoNotMatch = false;
            // Enregistrer le nouveau mot de passe et mettre à jour les CGU
            await Parse.Cloud.run("changePassword", {
              email: this.email,
              confirmPassword: this.confirmPassword,
            });
            console.log("Mot de passe mis à jour");
            var result = await this.actionsLogIn({
              email: this.email,
              password: this.confirmPassword,
              SessionToken: this.SessionToken,
              MailResponse: this.MailResponse,
            });
            console.log("result", result);
            // Code pour mettre à jour le mot de passe et marquer les CGU comme acceptées...
          } else {
            var result = await this.actionsLogIn({
              email: this.email,
              password: this.password,
              SessionToken: this.SessionToken,
              MailResponse: this.MailResponse,
            });
            console.log("result", result);
            if (
              result.error &&
              result.message.includes("abonnement a expiré")
            ) {
              this.errorAbonnementExpire = true;
              this.errorLogIn = false; // Ne pas afficher l'erreur de connexion générique
              console.log("Erreur d'abonnement expiré");
            }
          }
          this.errorLogIn = false;
        } catch (error) {
          // Gérer les erreurs de connexion
          console.log("Erreur de connexion", error);
          this.errorLogIn = true;

          // Vérifier si l'erreur est liée à un abonnement expiré
          if (error.message && error.message.includes("abonnement a expiré")) {
            this.errorAbonnementExpire = true;
            this.errorLogIn = false; // Ne pas afficher l'erreur de connexion générique
            console.log("Erreur d'abonnement expiré");
          }
        } finally {
          console.log("Connexion terminée");
          if (Parse.User.current()) {
            this.isLoading = false;
          } else {
            this.isLoading = false;
            if (!this.errorAbonnementExpire) {
              this.errorLogIn = true;
            }
          }
        }
      }
    },

    goToSuscribe() {
      this.$router.push("/inscription-credits");
    },
  },
};
</script>
<style scoped>
/* Base styles */
html {
  background-color: #3c3c3c;
  overflow-y: auto;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Layout containers */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app {
  flex: 1;
}

.containers {
  min-height: 60vh;
  margin-bottom: -50px;
  padding-bottom: 50px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Banner styles */
.subscribe-banner {
  background-color: #239380;
  color: white;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.banner-text {
  font-weight: 600;
  text-align: center;
  font-size: clamp(14px, 2.5vw, 18px);
}

.banner-button {
  background-color: white;
  color: #239380;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.banner-button:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

/* Notification banner */
.banner-notification {
  background-color: #d4ede9;
  color: #333;
  padding: 15px;
  border-left: 4px solid #239380;
  border-radius: 4px;
  text-align: center;
  margin: 20px auto;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.banner-notification p {
  margin: 0;
  font-weight: 500;
  font-size: clamp(14px, 2.5vw, 16px);
}

/* Form elements */
.buttonConnexion {
  margin-top: 15px;
  width: 100%;
}

/* Loading overlay */
.loading-overlay-connexion {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease-out;
}

.loading-content-connexion {
  text-align: center;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  width: min(300px, 90%);
}

.loading-spinner-connexion {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #239380;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

.loading-text-connexion {
  font-size: clamp(14px, 2.5vw, 18px);
  color: #333;
  font-weight: 500;
  line-height: 1.5;
}

/* Footer */
.footerM {
  margin-top: auto;
  width: 100%;
}

/* Animations */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .banner-content {
    padding: 10px;
  }

  .banner-text {
    margin-bottom: 10px;
  }

  .loading-content-connexion {
    width: 90%;
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .banner-button {
    width: 100%;
  }

  .loading-spinner-connexion {
    width: 40px;
    height: 40px;
  }
}
</style>
