import Vue from "vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";

// État global réactif
const versionState = new Vue({
  data: {
    NewVersionEnabled: false,
    credits: 0,
  },
});

Vue.prototype.$versionState = versionState;

// Utilitaires
const handleError = (error, context) => {
  console.error(`Erreur ${context}:`, error);
};

const setVuePrototype = (key, value) => {
  Vue.prototype[key] = value;
};

// Gestion des crédits
const fetchCredits = async () => {
  try {
    const response = await Parse.Cloud.run("getCredits");
    const credits = response.credits;
    versionState.credits = credits;
    setVuePrototype("$credits", credits);
    console.log("%cCrédits récupérés:", "color: green", credits);
    return credits;
  } catch (error) {
    handleError(error, "récupération des crédits");
    versionState.credits = 0;
    setVuePrototype("$credits", 0);
    return 0;
  }
};
const fetchGroups = async () => {
  try {
    const groupData = await Parse.Cloud.run("getUserGroupInfo");
    console.log(
      "%cInformations du groupe récupérées:",
      "color: green",
      groupData
    );
    return {
      essai_gratuit: groupData.essai_gratuit || false,
      type_abonnement: groupData.type_abonnement,
    };
  } catch (error) {
    handleError(error, "fetchGroups");
    return {
      essai_gratuit: false,
      type_abonnement: "gratuit",
    };
  }
};

export async function setGlobalVariables() {
  try {
    ParseConfig();
    const user = Parse.User.current();

    if (!user) return;

    console.log("%cUtilisateur récupéré", "color: blue", user);

    // Récupération des informations du groupe
    const groupInfo = await fetchGroups();

    // Définition des attributs utilisateur
    const therapeute = user.attributes.therapeute || false;
    const essaiGratuit = groupInfo.essai_gratuit;
    const typeAbonnement = groupInfo.type_abonnement;

    // Utiliser la nouvelle méthode utilitaire
    Vue.prototype.$setGlobalProperty("$Therapeute", therapeute);
    Vue.prototype.$setGlobalProperty("$EssaiGratuit", essaiGratuit);
    Vue.prototype.$setGlobalProperty(
      "$AccountLimited",
      therapeute && !essaiGratuit && typeAbonnement === "gratuit"
    );
    console.log(
      "%cCompte limité:",
      "color: blue",
      Vue.prototype.$AccountLimited
    );
    Vue.prototype.$setGlobalProperty(
      "$IsTherapeuteWithoutIdPatient",
      therapeute && !Vue.prototype.$IdPatient
    );

    if (
      user.attributes.new_version_enabled &&
      !versionState.NewVersionEnabled
    ) {
      versionState.NewVersionEnabled = true;
      Vue.prototype.$setGlobalProperty("$NewVersionEnabled", true);
    }

    // Récupération des crédits
    await fetchCredits();
  } catch (error) {
    handleError(error, "setGlobalVariables");
  }
}
// Fonctions de gestion d'état
export async function setNewVersionEnabled(enabled) {
  try {
    if (enabled !== versionState.NewVersionEnabled) {
      versionState.NewVersionEnabled = enabled;
      setVuePrototype("$NewVersionEnabled", enabled);
    }
  } catch (error) {
    handleError(error, "setNewVersionEnabled");
  }
}

export function checkAccountLimited(therapeute, essaiGratuit, typeAbonnement) {
  return therapeute && !essaiGratuit && typeAbonnement === 'gratuit';
}

export async function setIdPatientGlobal(id) {
  try {
    setVuePrototype("$IdPatient", id);
    setVuePrototype("$IsTherapeuteWithoutIdPatient", false);
  } catch (error) {
    handleError(error, "setIdPatientGlobal");
  }
}

export async function resetIdPatientGlobal() {
  try {
    setVuePrototype("$IdPatient", null);
    setVuePrototype("$IsTherapeuteWithoutIdPatient", true);
  } catch (error) {
    handleError(error, "resetIdPatientGlobal");
  }
}

export async function setLinksGlobal(links) {
  try {
    setVuePrototype("$LinksGlobal", links);
  } catch (error) {
    handleError(error, "setLinksGlobal");
  }
}

export async function resetLinksGlobal() {
  try {
    setVuePrototype("$LinksGlobal", null);
  } catch (error) {
    handleError(error, "resetLinksGlobal");
  }
}

export async function setEssaiGratuit(value) {
  try {
    setVuePrototype("$EssaiGratuit", value);
  } catch (error) {
    handleError(error, "setEssaiGratuit");
  }
}

export const updateCredits = fetchCredits;
